import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { FaCog, FaPlus, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { DeckData } from '../../interfaces/decks';
import ConfirmDeletePopup from '../../popups/confirmDeletePopup.tsx/ConfirmDeletePopup';
import CreateDeckPopup from '../../popups/createDeck/CreateDeckPopup';
import EditDeckPopup from '../../popups/editDeck/EditDeckPopup';
import './DecksPage.css';

const DecksPage: React.FC = () => {
  const { userData } = useUser();
  const languageCode = userData?.learning_language_code;
  const [decks, setDecks] = useState<DeckData[]>([]);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [deckToDelete, setDeckToDelete] = useState<DeckData | null>(null);
  const [deckToEdit, setDeckToEdit] = useState<DeckData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchDecks = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/decks/language_code/${languageCode}`
      );

      const data = response.data;
      setDecks(data);
    } catch (error) {
      console.error('Error fetching sorted deck data:', error);
    } finally {
      setLoading(false);
    }
  }, [languageCode]);

  useEffect(() => {
    fetchDecks();
  }, [fetchDecks]);

  const handleCreateDeckClick = () => {
    setShowCreatePopup(true);
  };

  const submitPopup = () => {
    setShowCreatePopup(false);
    setDeckToEdit(null);
    fetchDecks();
  };

  const confirmDeleteDeck = async () => {
    if (deckToDelete) {
      try {
        await axios.delete(`/api/decks/${deckToDelete.id}`);
        fetchDecks();
        setDeckToDelete(null);
      } catch (error) {
        console.error('Error deleting the deck:', error);
      }
    }
  };

  return (
    <>
      <div className="phn-page-container">
        <h1>Decks</h1>
        <div className="phn-vertical-container">
          <div className="phn-horizontal-group">
            <button className="phn-button" onClick={handleCreateDeckClick}>
              Create Deck
            </button>
          </div>
          {loading ? (
            <div className="decks-deck-items">
              {[...Array(3)].map((_, index) => (
                <div
                  key={index}
                  className="phn-white-card decks-deck-item skeleton"
                  style={{
                    height: '60px',
                  }}
                ></div>
              ))}
            </div>
          ) : (
            <div className="decks-deck-items">
              {decks.map((deck) => (
                <div
                  key={deck.id}
                  className="phn-white-card decks-deck-item"
                  onClick={() =>
                    navigate(`/cards`, { state: { selectedDeck: deck.id } })
                  }
                >
                  <h3 className="decks-deck-name">{deck.name}</h3>
                  <div className="decks-item-buttons">
                    <button
                      className="phn-icon-button  phn-button-tooltip"
                      tooltip-data="Add Card"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (deck.id !== null) {
                          navigate(`/add-card`, { state: { deckId: deck.id } });
                        }
                      }}
                    >
                      <FaPlus />
                    </button>
                    <button
                      className="phn-icon-button phn-button-tooltip"
                      tooltip-data="Edit Deck"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeckToEdit(deck);
                      }}
                    >
                      <FaCog />
                    </button>
                    <button
                      className="phn-icon-button  phn-button-tooltip"
                      tooltip-data="Delete Deck"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeckToDelete(deck);
                      }}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {showCreatePopup && languageCode && (
        <CreateDeckPopup
          onClose={() => setShowCreatePopup(false)}
          onSubmit={submitPopup}
          selectedLanguage={languageCode}
        />
      )}
      {deckToEdit && (
        <EditDeckPopup
          deck={deckToEdit}
          onSubmit={submitPopup}
          onClose={() => setDeckToEdit(null)}
        />
      )}
      {deckToDelete && (
        <ConfirmDeletePopup
          onClose={() => setDeckToDelete(null)}
          onConfirm={confirmDeleteDeck}
          message={`Are you sure you want to delete the deck "${deckToDelete.name}"?`}
        />
      )}
    </>
  );
};

export default DecksPage;
