import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import './SidebarLayout.css';

// Custom transitions
const SlideInRight = cssTransition({
  enter: 'slide-in-right',
  exit: 'slide-out-right',
});

const SlideInBottom = cssTransition({
  enter: 'slide-in-bottom',
  exit: 'slide-out-bottom',
});

const SidebarLayout: React.FC = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleSidebarToggle = (isCollapsed: boolean) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <>
      <ToastContainer
        hideProgressBar={true}
        position={isMobile ? 'bottom-center' : 'top-right'}
        autoClose={2000}
        transition={isMobile ? SlideInBottom : SlideInRight}
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
      />
      <Sidebar onToggle={handleSidebarToggle} />
      <div
        className={`main-content ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}
      >
        <Outlet />
      </div>
    </>
  );
};

export default SidebarLayout;
