import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PlayButton from '../playButton/PlayButton';
import './VoiceSelection.css';

interface VoiceSelectionProps {
  languageCode?: string;
  text?: string;
  disabled?: boolean;
  onVoiceChange?: (voiceId: string) => void;
  onSpeedChange?: (speed: number) => void;
}

const getRandomVoiceFromEntries = (entries: [string, unknown][]) => {
  if (entries.length > 0) {
    const randomIndex = Math.floor(Math.random() * entries.length);
    return entries[randomIndex][1] as string;
  }
  return ''; // Return empty string if no voices available
};

const VoiceSelection: React.FC<VoiceSelectionProps> = ({
  languageCode,
  text,
  disabled = false,
  onVoiceChange,
  onSpeedChange,
}) => {
  const [voiceEntries, setVoiceEntries] = useState<[string, unknown][]>([]);
  const [voices, setVoices] = useState<{ [key: string]: string }>({});
  const [selectedVoice, setSelectedVoice] = useState<string>('random');
  const [voiceId, setVoiceId] = useState<string>('');
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audioLoading, setAudioLoading] = useState<boolean>(false);
  const [audioSpeed, setAudioSpeed] = useState<number>(() => {
    const savedSpeed = localStorage.getItem('audioSpeed');
    return savedSpeed ? Number(savedSpeed) : 0.9;
  });

  const fetchVoices = useCallback(async () => {
    if (!languageCode) {
      console.error('No language code found');
      return;
    }

    try {
      const response = await axios.get(
        `/api/audio/speech/voices/${languageCode}`
      );
      const data = response.data;
      const voiceEntries = Object.entries(data);
      setVoiceEntries(voiceEntries);

      const updatedVoices = { Random: 'random', ...data };
      setVoices(updatedVoices);

      const rvid = getRandomVoiceFromEntries(voiceEntries);
      setVoiceId(rvid);
    } catch (error) {
      console.error('Error fetching voices:', error);
    }
  }, [languageCode]);

  useEffect(() => {
    fetchVoices();
  }, [fetchVoices]);

  useEffect(() => {
    if (voiceId) {
      onVoiceChange?.(voiceId);
    }
  }, [voiceId, onVoiceChange]);

  const handleVoiceChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newVoice = e.target.value;
    setSelectedVoice(newVoice);
    setAudioUrl(null);

    if (newVoice === 'random' && voiceEntries.length > 0) {
      const newRandomVoiceId = getRandomVoiceFromEntries(voiceEntries);
      setVoiceId(newRandomVoiceId);
      onVoiceChange?.(newRandomVoiceId);
    } else {
      setVoiceId(newVoice);
      onVoiceChange?.(newVoice);
    }
  };

  const handleSpeedChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSpeed = Number(e.target.value);
    setAudioSpeed(newSpeed);
    localStorage.setItem('audioSpeed', newSpeed.toString());
    setAudioUrl(null);
    onSpeedChange?.(newSpeed);
  };

  const handlePlay = async () => {
    if (!text || !selectedVoice) {
      throw new Error('No text or voice selected');
    }

    if (!audioUrl) {
      setAudioLoading(true);
      try {
        const response = await axios.post('/api/audio/speech', {
          text,
          voice_id: voiceId,
          language_code: languageCode,
          speed: audioSpeed,
        });
        setAudioUrl(response.data);
      } catch (error) {
        console.error('Error generating speech:', error);
        toast.error('Failed to generate speech');
        throw error;
      } finally {
        setAudioLoading(false);
      }
    }
  };

  return (
    <div className="voice-dropdowns">
      <label htmlFor="voice-dropdown">Voice:</label>
      <select
        id="voice-dropdown"
        value={selectedVoice}
        onChange={handleVoiceChange}
        disabled={disabled}
        className="phn-dropdown"
      >
        {Object.entries(voices).map(([key, value]) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
      </select>
      <select
        id="speed-dropdown"
        value={audioSpeed.toString()}
        onChange={handleSpeedChange}
        disabled={disabled}
        className="phn-dropdown speed-dropdown"
      >
        <option value="0.5">0.5x</option>
        <option value="0.6">0.6x</option>
        <option value="0.7">0.7x</option>
        <option value="0.8">0.8x</option>
        <option value="0.9">0.9x</option>
        <option value="1.0">1.0x</option>
      </select>
      <PlayButton
        src={audioUrl}
        onPlay={handlePlay}
        disabled={disabled || !text}
        isLoading={audioLoading}
        autoPlay={true}
      />
    </div>
  );
};

export default VoiceSelection;
