// src/pages/DashboardPage.tsx
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { TbCardsFilled } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useUser } from '../../context/UserContext';
import { ReviewData } from '../../interfaces/decks';
import { GraphData } from '../../interfaces/stats';
import { StoryData } from '../../interfaces/stories';
import './DashboardPage.css';

const DashboardPage: React.FC = () => {
  const { userData } = useUser();
  const languageCode = userData?.learning_language_code;
  const [stories, setStories] = useState<StoryData[]>([]);
  const [reviewData, setReviewData] = useState<ReviewData[]>([]);
  const [userNDecks, setUserNDecks] = useState<number>(0);
  const [vocabSizeData, setVocabSizeData] = useState<
    { date: string; size: number }[]
  >([]);
  const [cardsReviewedData, setCardsReviewedData] = useState<
    { date: string; size: number }[]
  >([]);
  const [reviewLoading, setReviewLoading] = useState(true);
  const [storiesLoading, setStoriesLoading] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const fetchReviewData = useCallback(async () => {
    try {
      const response = await axios.get('/api/decks/review/today');
      const data = response.data;
      setReviewData(data.review_decks);
      setUserNDecks(data.user_n_decks);
      setReviewLoading(false);
    } catch (error) {
      console.error('Error fetching review data:', error);
    }
  }, []);

  const fetchStories = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/stories/language_code/${languageCode}`
      );
      const data = response.data;
      setStories(data);
      setStoriesLoading(false);
    } catch (error) {
      console.error('Error fetching sorted story data:', error);
    }
  }, [languageCode]);

  const fetchVocabSizeData = useCallback(async () => {
    try {
      const response = await axios.get(`/api/stats/vocab_size/${languageCode}`);
      const data: GraphData = response.data;
      const formattedData = Object.entries(data).map(([date, size]) => ({
        date,
        size,
      }));
      setVocabSizeData(formattedData);
      setStatsLoading(false);
    } catch (error) {
      console.error('Error fetching vocabulary size data:', error);
    }
  }, [languageCode]);

  const fetchCardsReviewedData = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/stats/cards_reviewed/${languageCode}`
      );
      const data: GraphData = response.data;
      const formattedData = Object.entries(data).map(([date, size]) => ({
        date,
        size,
      }));
      setCardsReviewedData(formattedData);
    } catch (error) {
      console.error('Error fetching vocabulary size data:', error);
    }
  }, [languageCode]);

  useEffect(() => {
    if (languageCode) {
      fetchReviewData();
      fetchStories();
      fetchVocabSizeData();
      fetchCardsReviewedData();
    }
  }, [
    fetchReviewData,
    fetchStories,
    fetchVocabSizeData,
    fetchCardsReviewedData,
    languageCode,
  ]);

  const formatXAxis = (tickItem: string) => {
    return format(parseISO(tickItem), 'EEE');
  };

  const vocabTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{format(parseISO(label), 'EEEE')}</p>
          <p className="intro">{`${payload[0].value} words`}</p>
        </div>
      );
    }
    return null;
  };

  const cardsTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{format(parseISO(label), 'EEEE')}</p>
          <p className="intro">{`${payload[0].value} cards`}</p>
        </div>
      );
    }
    return null;
  };

  const ReviewSkeleton = () => (
    <div className="dashboard-review-squares">
      {[...Array(3)].map((_, index) => (
        <div
          key={index}
          className="phn-gray-card dashboard-review-square skeleton"
        ></div>
      ))}
    </div>
  );

  const StoriesSkeleton = () => (
    <div className="dashboard-stories-list">
      {[...Array(2)].map((_, index) => (
        <div
          key={index}
          className="phn-gray-card dashboard-story-item skeleton"
          style={{
            height: '60px',
          }}
        ></div>
      ))}
    </div>
  );

  const StatsSkeleton = () => (
    <>
      <div className="dashboard-vocab-size-stats skeleton"></div>
      <div className="dashboard-cards-reviewed-stats skeleton"></div>
    </>
  );

  return (
    <div className="phn-page-container">
      <h1>Welcome back, {userData?.name}!</h1>
      <div className="phn-divide-container">
        <div className="phn-vertical-content dashboard-content">
          <div className="phn-white-card dashboard-todays-review">
            <h2>Today's Review</h2>
            {reviewLoading ? (
              <ReviewSkeleton />
            ) : reviewData.length > 0 ? (
              <div className="dashboard-review-squares">
                {reviewData.map((review) => (
                  <div
                    key={review.id}
                    className="phn-gray-card dashboard-review-square"
                    onClick={() =>
                      navigate(`/review`, { state: { deckId: review.id } })
                    }
                  >
                    {/* <div className="dashboard-review-language">{review.language}</div> */}
                    <div
                      className={`dashboard-review-name ${review.name.length > 16 ? 'long-name' : ''}`}
                    >
                      {review.name}
                    </div>
                    <div className="dashboard-review-details">
                      <span className="dashboard-new-cards">
                        {review.new_cards}
                      </span>
                      <span className="dashboard-learning-cards">
                        {review.learning_cards}
                      </span>
                      <span className="dashboard-review-cards">
                        {review.review_cards}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="dashboard-review-complete">
                {userNDecks > 0 ? (
                  <>
                    <h2>Review Complete!</h2>
                    <h3>Check Back Tomorrow</h3>
                  </>
                ) : (
                  <>
                    <h2>No Decks Yet</h2>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="phn-white-card dashboard-keep-reading">
            <h2>Continue Reading</h2>
            {storiesLoading ? (
              <StoriesSkeleton />
            ) : stories.length > 0 ? (
              <div className="dashboard-stories-list">
                {stories.map(
                  (story) =>
                    story.status === 'Complete' && (
                      <div
                        key={story.id}
                        className="phn-gray-card dashboard-story-item"
                        onClick={() => {
                          if (story.status === 'Complete') {
                            navigate(`/read-story`, {
                              state: { selectedStory: story.id },
                            });
                          }
                        }}
                      >
                        <div className="dashboard-story-title">
                          <span className="dashboard-story-title-main">
                            {story.target_language_title}
                          </span>
                          <span className="dashboard-story-title-sub">
                            {story.native_language_title}
                          </span>
                        </div>
                      </div>
                    )
                )}
              </div>
            ) : (
              <div className="dashboard-no-stories">
                <h2>No Stories Yet</h2>
              </div>
            )}
          </div>
        </div>
        <div className="phn-vertical-content dashboard-content">
          <div className="phn-white-card dashboard-stats">
            <h2>This Week's Progress</h2>
            {statsLoading ? (
              <StatsSkeleton />
            ) : (
              <>
                {vocabSizeData.length > 0 && (
                  <div className="dashboard-vocab-size-stats">
                    <h3>Vocabulary</h3>
                    <ResponsiveContainer width="100%" height="80%">
                      <LineChart
                        data={vocabSizeData}
                        margin={{ top: 20, right: 60, bottom: 5 }}
                      >
                        <XAxis
                          dataKey="date"
                          tickFormatter={formatXAxis}
                          strokeWidth={3}
                          tick={{ fontSize: 12 }}
                        />
                        <YAxis
                          domain={[
                            Math.max(
                              Math.min(
                                ...vocabSizeData.map((data) => data.size)
                              ) - 10,
                              0
                            ),
                            'dataMax',
                          ]}
                          strokeWidth={3}
                          tick={{ fontSize: 12 }}
                        />
                        <Tooltip
                          content={vocabTooltip}
                          contentStyle={{
                            backgroundColor: '#f5f5f5',
                            borderRadius: '10px',
                          }}
                        />
                        <Line
                          type="monotone"
                          dataKey="size"
                          name="Words"
                          stroke="var(--primary-main)"
                          strokeWidth={4}
                          dot={{ r: 5 }}
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}
                {cardsReviewedData.length > 0 && (
                  <div className="dashboard-cards-reviewed-stats">
                    <h3>Cards Reviewed</h3>
                    <ResponsiveContainer width="100%" height="80%">
                      <LineChart
                        data={cardsReviewedData}
                        margin={{ top: 20, right: 60, bottom: 5 }}
                      >
                        <XAxis
                          dataKey="date"
                          tickFormatter={formatXAxis}
                          strokeWidth={3}
                          tick={{ fontSize: 12 }}
                        />
                        <YAxis strokeWidth={3} tick={{ fontSize: 12 }} />
                        <Tooltip
                          content={cardsTooltip}
                          contentStyle={{
                            backgroundColor: '#f5f5f5',
                            borderRadius: '10px',
                          }}
                        />
                        <Line
                          type="monotone"
                          dataKey="size"
                          name="Cards"
                          stroke="var(--primary-main)"
                          strokeWidth={4}
                          dot={{ r: 5 }}
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="floating-action-menu">
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              className="fab-menu"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              {/* <motion.button
                className="fab-menu-item"
                whileHover={{ scale: 1.1 }}
                onClick={() => navigate('/decks/create')}
              >
                <FaLayerGroup /> Create Deck
              </motion.button> */}
              <motion.button
                className="fab-menu-item"
                whileHover={{ scale: 1.1 }}
                onClick={() => navigate('/add-card')}
              >
                <TbCardsFilled /> Add Card
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>
        <motion.button
          className="fab-button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <FaPlus className={`fab-icon ${isMenuOpen ? 'rotate' : ''}`} />
        </motion.button>
      </div>
    </div>
  );
};

export default DashboardPage;
