import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditableTranslation from '../../components/editableTranslation/EditableTranslation';
import VoiceSelection from '../../components/voiceSelection/VoiceSelection';
import { useUser } from '../../context/UserContext';
import { useDebounce } from '../../hooks/useDebounce';
import { Translation } from '../../interfaces/cards';
import { Deck } from '../../interfaces/decks';
import { getPhoneticName, isPhoneticsSupported } from '../../utils/formatting';
import './AddCardPage.css';

const AddCardPage: React.FC = () => {
  const { userData } = useUser();
  const languageCode = userData?.learning_language_code;
  const [decks, setDecks] = useState<Deck[]>([]);
  const [deckId, setDeckId] = useState<number>();
  const [deck, setDeck] = useState<Deck | null>(null);
  const [phrase, setPhrase] = useState<string>('');
  const [lastTranslatedPhrase, setLastTranslatedPhrase] = useState<string>('');
  const [translations, setTranslations] = useState<Translation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedVoiceId, setSelectedVoiceId] = useState<string>('');
  const [audioSpeed, setAudioSpeed] = useState<number>(() => {
    const savedSpeed = localStorage.getItem('audioSpeed');
    return savedSpeed ? Number(savedSpeed) : 0.9;
  });
  const location = useLocation();
  const [showManualInput, setShowManualInput] = useState<boolean>(false);
  const [manualTargetPhrase, setManualTargetPhrase] = useState<string>('');
  const [manualPhonetic, setManualPhonetic] = useState<string>('');

  useEffect(() => {
    const state = location.state as { deckId?: number };
    if (location.state && state.deckId) {
      setDeckId(state.deckId);
    }
  }, [location.state]);

  const fetchDecks = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/decks/language_code/${languageCode}`
      );

      const data: Deck[] = response.data;
      setDecks(data);

      if (location.state && location.state.deckId) {
        setDeckId(location.state.deckId);
      } else {
        setDeckId(data[0]?.id);
      }
    } catch (error) {
      console.error('Error fetching decks:', error);
    }
  }, [location.state, languageCode]);

  useEffect(() => {
    fetchDecks();
  }, [fetchDecks]);

  const handleDeckChange = (id: string) => {
    const selectedId = parseInt(id, 10);
    setDeckId(selectedId);
  };

  const fetchDeck = useCallback(async () => {
    try {
      const response = await axios.get(`/api/decks/${deckId}`);
      const data = response.data;
      setDeck(data);
    } catch (error) {
      console.error('Error fetching deck details:', error);
    }
  }, [deckId]);

  // Fetch deck details
  useEffect(() => {
    if (deckId) {
      fetchDeck();
    }
  }, [fetchDeck, deckId]);

  const handleTranslate = async () => {
    setTranslations([]);
    try {
      setLoading(true);

      const translateData = {
        sentence: phrase,
        language_code: deck?.language_code,
      };

      const response = await axios.post(
        '/api/generate/text/translations',
        translateData
      );

      const translatedResponse: Translation[] = response.data;

      const translationsWithIDs = translatedResponse.map((item, index) => ({
        ...item,
        id: index,
        selected: false,
      }));

      setTranslations(translationsWithIDs);
      setLastTranslatedPhrase(phrase); // Update last translated phrase
    } catch (error: any) {
      console.error('Error translating phrase:', error);
      toast.error(`Error: ${error.response.data.detail}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (id: number) => {
    const updatedTranslations = translations.map((translation) =>
      translation.id === id
        ? { ...translation, selected: !translation.selected }
        : translation
    );
    setTranslations(updatedTranslations);
  };

  const handleSaveTranslation = async (id: number, newTranslation: string) => {
    if (newTranslation !== translations[id].translation) {
      try {
        // First, check if phonetic needs to be updated
        const updatePhonetic = translations.find((t) => t.id === id)?.phonetic;

        if (updatePhonetic) {
          const response = await axios.post('/api/generate/phonetics', {
            sentence: newTranslation,
            language_code: deck?.language_code,
          });
          const data = response.data;

          // Update both translation and phonetic in one state update
          setTranslations(
            translations.map((translation) =>
              translation.id === id
                ? {
                    ...translation,
                    translation: newTranslation,
                    phonetic: data.phonetic,
                  }
                : translation
            )
          );
        } else {
          // Update only the translation if phonetic does not need updating
          setTranslations(
            translations.map((translation) =>
              translation.id === id
                ? {
                    ...translation,
                    translation: newTranslation,
                  }
                : translation
            )
          );
        }
      } catch (error: any) {
        console.error('Failed to save translation:', error);
        try {
          toast.error(`Error: ${error.response.data.detail}`);
        } catch (error: any) {
          toast.error('An error occurred while saving the translation.');
        }
      }
    }
  };

  const handleAddCards = async () => {
    const selectedItems = translations.filter(
      (translation) => translation.selected
    );

    if (selectedItems.length === 0) {
      toast.warning('Please select at least one card to add.');
      return;
    }

    try {
      const requestBody = selectedItems.map((item) => ({
        deck_id: deck?.id,
        native_language: phrase,
        target_language: item.translation,
        phonetic: item.phonetic,
        formality: item.formality,
        voice_id: selectedVoiceId,
        speed: audioSpeed,
      }));

      await axios.post(`/api/cards/deck/${deckId}`, requestBody);
      toast.success('Card Added!', { autoClose: 3000 });
    } catch (error: any) {
      try {
        toast.error(`Error adding cards: ${error.response.data.detail}`);
      } catch (error: any) {
        toast.error('An error occurred while adding the cards.');
      }
    } finally {
      // Reset state after attempting to add cards
      setPhrase('');
      setTranslations([]);
    }
  };

  const handleManualAdd = async () => {
    if (!manualTargetPhrase || !phrase) {
      toast.warning('Please fill in both languages');
      return;
    }

    try {
      const requestBody = [
        {
          deck_id: deck?.id,
          native_language: phrase,
          target_language: manualTargetPhrase,
          phonetic: manualPhonetic,
          formality: 'casual',
          voice_id: selectedVoiceId,
          speed: audioSpeed,
        },
      ];

      await axios.post(`/api/cards/deck/${deckId}`, requestBody);
      toast.success('Card Added!', { autoClose: 3000 });
    } catch (error: any) {
      try {
        toast.error(`Error adding cards: ${error.response.data.detail}`);
      } catch (error: any) {
        toast.error('An error occurred while adding the cards.');
      }
    } finally {
      // Reset state after attempting to add cards
      setPhrase('');
      setTranslations([]);
      setManualTargetPhrase('');
      setManualPhonetic('');
    }
  };

  const debouncedUpdatePhonetic = useDebounce(async (value: string) => {
    if (!deck?.language_code || !value) return;

    try {
      const response = await axios.post('/api/generate/phonetics', {
        sentence: value,
        language_code: deck.language_code,
      });
      const data = response.data;
      setManualPhonetic(data.phonetic);
    } catch (error) {
      console.error('Error fetching phonetic:', error);
    }
  }, 300);

  const handleManualTargetPhraseChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setManualTargetPhrase(value);
      if (isPhoneticsSupported(deck?.language_code || '')) {
        debouncedUpdatePhonetic(value);
      }
    },
    [debouncedUpdatePhonetic, deck?.language_code]
  );

  return (
    <div className="phn-page-container">
      <h1>Add Card</h1>
      <div className="phn-vertical-container">
        <div className="phn-horizontal-group">
          <label>Deck:</label>
          <select
            className="phn-dropdown cards-deck-select"
            value={deckId || ''}
            onChange={(e) => handleDeckChange(e.target.value)}
          >
            {decks.map((deck) => (
              <option key={deck.id} value={deck.id}>
                {deck.name}
              </option>
            ))}
          </select>
          {!translations.length && (
            <button
              onClick={() => setShowManualInput(!showManualInput)}
              className="manual-input-toggle"
            >
              {showManualInput ? 'Auto Translate' : 'Manual Card'}
            </button>
          )}
        </div>
        <div className="input-container">
          <input
            type="text"
            id="phrase-input"
            placeholder={
              showManualInput ? 'Native language' : 'Phrase to translate...'
            }
            value={phrase}
            onChange={(e) => setPhrase(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !showManualInput) {
                handleTranslate();
              }
            }}
            className="phn-input"
            disabled={loading}
          />
          {!showManualInput && (
            <button
              onClick={handleTranslate}
              className="phn-button"
              disabled={loading || !phrase || phrase === lastTranslatedPhrase}
            >
              {loading ? 'Translating...' : 'Translate'}
            </button>
          )}
        </div>

        {showManualInput && (
          <>
            <div className="input-container">
              <input
                type="text"
                placeholder={`Target language`}
                value={manualTargetPhrase}
                onChange={handleManualTargetPhraseChange}
                className="phn-input"
              />
            </div>
            {isPhoneticsSupported(deck?.language_code || '') && (
              <div className="input-container">
                <input
                  type="text"
                  placeholder={`${getPhoneticName(deck?.language_code || '')}`}
                  value={manualPhonetic}
                  onChange={(e) => setManualPhonetic(e.target.value)}
                  className="phn-input"
                />
              </div>
            )}
            <VoiceSelection
              languageCode={deck?.language_code}
              text={manualTargetPhrase}
              disabled={!manualTargetPhrase}
              onVoiceChange={(voiceId) => {
                setSelectedVoiceId(voiceId);
              }}
              onSpeedChange={(speed) => {
                setAudioSpeed(speed);
              }}
            />
            <div className="phn-horizontal-group">
              <button
                onClick={() => {
                  handleManualAdd();
                }}
                className="phn-button add-cards-button"
                disabled={!manualTargetPhrase}
              >
                Add Card
              </button>
            </div>
          </>
        )}

        {translations.length > 0 && (
          <>
            <h4>Select cards to add:</h4>
            <div className="translation-list">
              {translations.map((translation) => (
                <div
                  key={translation.id}
                  className={`phn-white-card translation-item ${translation.selected ? 'selected' : ''}`}
                  onClick={() => handleCheckboxChange(translation.id)}
                >
                  <div className="translation-details">
                    <EditableTranslation
                      translation={translation.translation}
                      onSave={(newTranslation) =>
                        handleSaveTranslation(translation.id, newTranslation)
                      }
                    />
                    <div className="phonetic">{translation.phonetic}</div>
                    <div className="formality">{translation.formality}</div>
                  </div>
                </div>
              ))}
            </div>
            <VoiceSelection
              languageCode={deck?.language_code}
              text={translations.find((t) => t.selected)?.translation}
              disabled={!translations.some((t) => t.selected)}
              onVoiceChange={(voiceId) => {
                setSelectedVoiceId(voiceId);
              }}
              onSpeedChange={(speed) => {
                setAudioSpeed(speed);
              }}
            />
            <div className="phn-horizontal-group">
              <button
                onClick={handleAddCards}
                className="phn-button add-cards-button"
                disabled={!translations.some((t) => t.selected)}
              >
                Add Card
                {translations.filter((t) => t.selected).length > 1 ? 's' : ''}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddCardPage;
