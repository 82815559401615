import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { FaCheck, FaGoogle, FaTimes } from 'react-icons/fa'; // Importing icons from react-icons library
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LoginResponse } from '../../interfaces/auth';
import './SignupPage.css'; // Importing CSS for styling

interface SignupError {
  detail: string;
}

const SignupPage: React.FC = () => {
  const { isAuthenticated, login } = useAuth();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [navigate, isAuthenticated]);

  useEffect(() => {
    setFormValid(
      email.trim() !== '' && passwordValid && passwordMatch && agreedToTerms
    );
  }, [email, passwordValid, passwordMatch, agreedToTerms]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordValid(newPassword.length >= 8); // Example: Password must be at least 8 characters
    setPasswordMatch(newPassword === confirmPassword);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    setPasswordMatch(password === newConfirmPassword);
  };

  const handleGoogleSignupSuccess = async (codeResponse: CodeResponse) => {
    const authorizationCode = codeResponse.code;

    try {
      const response = await fetch('/api/users/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        body: JSON.stringify({
          code: authorizationCode,
        }),
      });

      if (!response.ok) {
        const data: SignupError = await response.json();
        throw new Error(data.detail);
      }

      const data: LoginResponse = await response.json();
      login(data.access_token.token);
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.error('Signup failed:', message);
      setErrorMessage(`${message}`);
    }
  };

  const handleGoogleSignupFailure = () => {
    console.log('Google Signup Failed');
    setErrorMessage('Google signup failed. Please try again.');
  };

  const googleSignup = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: handleGoogleSignupSuccess,
    onError: handleGoogleSignupFailure,
  });

  const handleSignup = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const requestBody = {
        email: email,
        name: name,
        password: password,
      };

      const response = await fetch('/api/users/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        body: JSON.stringify(requestBody),
      });
      if (response.status === 200) {
        setSignupSuccess(true);
      } else {
        const data: SignupError = await response.json();
        throw new Error(data?.detail);
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.error('Signup failed:', message);
      setErrorMessage(`Signup failed: ${message}`);
      setName('');
      setEmail(''); // Clear email field
      setPassword(''); // Clear password field
      setConfirmPassword(''); // Clear confirm password field
    }
  };

  return (
    <div className="signup-container">
      {signupSuccess ? (
        <div className="signup-success">
          <h2>Signup Successful!</h2>
          <p>You will be redirected to the login page.</p>
          <button
            onClick={() => navigate('/login')}
            className="signup-continue-button"
          >
            Continue
          </button>
        </div>
      ) : (
        <div className="signup-form">
          <h3 className="signup-title">Create an Account</h3>
          <div className="signup-input-group">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="signup-input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="signup-input-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            {!passwordValid && (
              <div className="signup-password-strength">
                Password must be at least 8 characters long.
              </div>
            )}
          </div>
          <div className="signup-input-group">
            <div className="signup-password-confirm">
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              {confirmPassword && (
                <div className="signup-password-match">
                  {passwordMatch ? (
                    <FaCheck className="signup-green-check" />
                  ) : (
                    <FaTimes className="signup-red-cross" />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="signup-terms">
            <input
              type="checkbox"
              id="terms"
              checked={agreedToTerms}
              onChange={(e) => setAgreedToTerms(e.target.checked)}
              required
            />
            <label htmlFor="terms">
              I agree to the
              <Link to="/terms-of-use" className="signup-terms-link">
                Terms of Service
              </Link>
            </label>
          </div>
          {errorMessage && (
            <div className="signup-error-message">{errorMessage}</div>
          )}
          <button
            type="submit"
            className="signup-submit-button"
            disabled={!formValid}
            onClick={handleSignup}
          >
            Sign Up
          </button>
          <div className="signup-strikethrough-line">
            <span>OR</span>
          </div>
          <div className="signup-google">
            <button
              onClick={() => googleSignup()}
              className="signup-google-button"
            >
              <FaGoogle className="signup-google-icon" /> Sign up with Google
            </button>
          </div>
          <div className="signup-login-link-container">
            <p>
              Already have an account?{' '}
              <Link to="/login" className="signup-login-link">
                Login
              </Link>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignupPage;
