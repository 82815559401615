import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import LanguageSelect from './components/languageSelect/LanguageSelect';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './context/PrivateRoute';
import { UserProvider } from './context/UserContext';
import SidebarLayout from './layouts/sidebarLayout/SidebarLayout';
import AddCardPage from './pages/addCard/AddCardPage';
import CardsPage from './pages/cards/CardsPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import DecksPage from './pages/decks/DecksPage';
import LoginPage from './pages/login/LoginPage';
import PaymentPage from './pages/payment/PaymentPage';
import ReadStoryPage from './pages/readStory/ReadStoryPage';
import ReviewPage from './pages/review/ReviewPage';
import SignupPage from './pages/signup/SignupPage';
import StoriesPage from './pages/stories/StoriesPage';
import VocabPage from './pages/vocab/VocabPage';
import stripePromise from './stripe';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthProvider>
        <UserProvider>
          <Elements stripe={stripePromise}>
            <Router>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route
                  path="/language-select"
                  element={
                    <PrivateRoute>
                      <LanguageSelect />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/payment"
                  element={
                    <PrivateRoute>
                      <PaymentPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <SidebarLayout />
                    </PrivateRoute>
                  }
                >
                  <Route index element={<Navigate to="/dashboard" replace />} />
                  <Route path="dashboard" element={<DashboardPage />} />
                  <Route path="decks" element={<DecksPage />} />
                  <Route path="cards" element={<CardsPage />} />
                  <Route path="vocab" element={<VocabPage />} />
                  <Route path="stories" element={<StoriesPage />} />
                  <Route path="read-story" element={<ReadStoryPage />} />
                  <Route path="add-card" element={<AddCardPage />} />
                  <Route path="review" element={<ReviewPage />} />
                </Route>
              </Routes>
            </Router>
          </Elements>
        </UserProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
