import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { CardType } from '../../interfaces/cards';
import { DeckData } from '../../interfaces/decks';
import './EditDeckPopup.css';
interface EditDeckPopupProps {
  deck: DeckData;
  onSubmit: () => void;
  onClose: () => void;
}

const EditDeckPopup: React.FC<EditDeckPopupProps> = ({
  deck,
  onSubmit,
  onClose,
}) => {
  const [deckName, setDeckName] = useState<string>(deck.name);
  const [newLimit, setNewLimit] = useState<number>(deck.new_limit);
  const [reviewLimit, setReviewLimit] = useState<number>(deck.review_limit);
  const [cardTypes, setCardTypes] = useState<CardType[]>(deck.card_types);
  const [availableCardTypes, setAvailableCardTypes] = useState<CardType[]>([]);

  const fetchCardTypes = useCallback(async () => {
    try {
      const response = await axios.get('/api/cards/types');
      setAvailableCardTypes(response.data);
    } catch (error) {
      console.error('Error fetching card types:', error);
    }
  }, []);

  useEffect(() => {
    fetchCardTypes();
  }, [fetchCardTypes]);

  const handleCardTypeToggle = (type: CardType) => {
    setCardTypes((prevTypes) => {
      const isSelected = prevTypes.some((t) => t.id === type.id);
      if (isSelected) {
        return prevTypes.filter((t) => t.id !== type.id);
      } else {
        return [...prevTypes, type];
      }
    });
  };

  const handleSave = async () => {
    try {
      await axios.put(`/api/decks/${deck.id}`, {
        name: deckName,
        new_limit: newLimit,
        review_limit: reviewLimit,
        card_types: cardTypes.map((type) => type.id),
      });
      onSubmit();
    } catch (error) {
      console.error('Error updating deck:', error);
      onClose();
    }
  };

  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Edit Deck</h2>
        <div className="phn-popup-form">
          <label>
            Name
            <input
              id="name"
              type="text"
              value={deckName}
              onChange={(e) => setDeckName(e.target.value)}
            />
          </label>
          <label>
            Daily New Card Limit
            <input
              id="newLimit"
              type="text"
              value={newLimit}
              onChange={(e) => {
                const value = e.target.value.trim(); // Trim whitespace
                if (value === '' || isNaN(parseInt(value))) {
                  setNewLimit(0); // Clear the state if input is empty or NaN
                } else {
                  setNewLimit(parseInt(value)); // Parse and set the numeric value
                }
              }}
            />
          </label>
          <label>
            Daily Review Card Limit
            <input
              id="reviewLimit"
              type="text"
              value={reviewLimit}
              onChange={(e) => {
                const value = e.target.value.trim(); // Trim whitespace
                if (value === '' || isNaN(parseInt(value))) {
                  setReviewLimit(0); // Clear the state if input is empty or NaN
                } else {
                  setReviewLimit(parseInt(value)); // Parse and set the numeric value
                }
              }}
            />
          </label>
          <div className="phn-card-types-section">
            <label>Card Types</label>
            <div className="phn-card-types-grid">
              {availableCardTypes.map((type) => {
                const isSelected = cardTypes.some((t) => t.id === type.id);
                return (
                  <div
                    key={type.id}
                    className={`phn-card-type-chip ${isSelected ? 'selected' : ''}`}
                    onClick={() => handleCardTypeToggle(type)}
                  >
                    {type.name}
                    {isSelected && <span className="phn-check-icon">✓</span>}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="phn-popup-button-container">
            <button className="phn-button" onClick={handleSave}>
              Save
            </button>
            <button className="phn-cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDeckPopup;
